import zilvercms from "./_utils";

// microcart update event

$('.js-microcart').each(function(){
    var $cart = $(this),
        $toggle = $('.js-microcart-toggle'),
        $inner = $('.js-microcart-inner'),
        $close = $('.js-microcart-close'),
        $nav = $('.navbar-collapse');

    $inner.hide();

    $toggle.on('click', function(){

        $cart.slideToggle('fast');
        $('.js-header-overlay').toggle();

        $('.js-header-overlay').on('click', e => {
            $cart.slideUp('fast');
            $('.js-header-overlay').hide();
        })

        if($cart.hasClass('active')){
            setTimeout(update_microcart, 400);
        }

        $nav.each(function(){
            $(this).collapse('hide');
        });

        return false;
    });

    $close.on('click', function(){
        $cart.slideUp('fast');
        $('.js-header-overlay').hide();
    });

    update_microcart();
});

function update_microcart(){

    var $cart = $('.js-microcart'),
        $toggle = $('.js-microcart-toggle'),
        $empty = $('.js-microcart-empty'),
        $inner = $('.js-microcart-inner'),
        $products = $('.js-microcart-products');

    // Current product properties, if any
    var $productForm = $('.product-form');
    if($productForm) {
        var currentProductId = parseInt($productForm.find('input[name="productkey"]').val());
        var currentProductMaxCount = $productForm.find('.js-product-count').data('max');
    }

    $.getJSON('/bjax/webshop/Getobjectasjson/cart/', function(data) {
        console.log(data)
        if(data.itemcount > 0) {
            $empty.hide();
            $cart.removeClass('empty');
            $toggle.removeClass('empty');

            var itemcount = data.itemcount;

            if(itemcount > 4){
                itemcount = 4;
            }
            $('.js-microcart-items-shown').text(itemcount);
            $('.js-microcart-items-total').text(data.itemcount);
            $('.js-microcart-subtotal').html(zilvercms.currencyHelper(data.subtotal));
            if (data.shipping_excl_btw === 0) {
                $('.js-microcart-shipping').addClass("text-success").text(function() {
                    return $(this).data('translation');
                });
            } else {
                $('.js-microcart-shipping').html(zilvercms.currencyHelper(data.shipping_excl_btw));
            }
            $('.js-microcart-total').html(zilvercms.currencyHelper(data.total_excl_btw));


            $products.empty();

            for(var i = 0; i < data.items.length; i++) {
                var itemId = data.items[i].product_id;

                if(typeof currentProductId !== 'undefined' && currentProductId === itemId) {
                    var itemCount = parseInt(data.items[i].count);
                    if(itemCount >= currentProductMaxCount) {
                        $productForm.find('.js-product-count').val(0).attr({
                            'data-min': 0,
                            'data-max': 0
                        });
                        $productForm.find('button[type="submit"]').attr('disabled', 'disabled');
                    } else {
                        $productForm.find('.js-product-count').attr('data-max', (currentProductMaxCount - itemCount));
                    }
                }

                if(i < 4) {
                    $products.append('<li><a class="microcart__product" href="'+data.items[i].product_url+'"><span class="microcart__product-image me-2"><img src="'+zilvercms.imageHelper(data.items[i].image_url, '85x57')+'" alt="'+data.items[i].name+'"></span><span class="text microcart__product__title">'+data.items[i].name+'</span><span class="text">'+data.items[i].count+'x</span><span class="text mx-2">|</span><span class="text">'+zilvercms.currencyHelper(data.items[i].current_price_excl_btw)+'</span></a></li>');
                }
            }

            $products.find('.js-pricetotal').each(function() {
                $(this).html(zilvercms.currencyHelper($(this).html()));
            });

            $inner.show();
        }else{
            $cart.addClass('empty');
            $inner.hide();
            $empty.show();

            $('.navbar-mobile-menu').addClass('highlighted');
        }

        $('.header__microcart-number').attr('data-count', data.itemcount).html(data.itemcount);
        $('.header__microcart-count').attr('data-count', data.itemcount);
        $('.js-microcart-toggle-products').html(zilvercms.currencyHelper(data.total));
    });
}

export default update_microcart;
