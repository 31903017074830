import axios from 'axios';

$(function() {

    // Logout button
    $('body').on('click', '.js-customer-logout', async () => {
        const response = await axios.post('/bjax/Customer/logout');
        response.status === 205 && (window.location.href = '/Customer/Login');
    });

});