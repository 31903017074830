// Base stylesheet needed for every page
import './scss/app.scss';
import './scripts/nav';
import './scripts/header';
import './scripts/_microcart';

// Lazy load page depended scripts and stylesheets method
const loadComponent = async name => {
    const {default: component} = await import(`./${name}.js`);

    return component();
}

// Import everything in directory method used for example for images
const importAll = (r) => r.keys().forEach(r);

// Include page depended scripts and styles example
if(document.querySelector('.js-components') !== null) {
    loadComponent('components');
}

if (document.querySelector('.js-customer') !== null) {
    loadComponent('customer');

    if(document.querySelector('.js-register') !== null) {
        loadComponent('register');
    }
}

if (document.querySelector('.js-productdetails') !== null) {
    loadComponent('productdetails');
}

if (document.querySelector('.js-productlist') !== null) {
    loadComponent('productlist');
}

if (document.querySelector('.js-cart') !== null) {
    loadComponent('cart');
}

if (document.querySelector('.js-checkout') !== null) {
    loadComponent('checkout');
}

// Importing all images
const images = require.context('./images', true,  /\.(png|jpe?g|gif)$/);
importAll(images);

