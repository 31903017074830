import zilvercms from "./_utils";

$(function() {

    // Mobile nav 
    $('body').on('click', '.js-toggle-nav', e => {
        $('.js-nav').slideToggle('fast');
        if ($(e.currentTarget).hasClass('open')) {
            $(e.currentTarget).html('<svg width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.5 3.125h13c.25 0 .5-.219.5-.5v-1.25c0-.25-.25-.5-.5-.5H.5c-.281 0-.5.25-.5.5v1.25c0 .281.219.5.5.5zm0 5h13c.25 0 .5-.219.5-.5v-1.25c0-.25-.25-.5-.5-.5H.5c-.281 0-.5.25-.5.5v1.25c0 .281.219.5.5.5zm0 5h13c.25 0 .5-.219.5-.5v-1.25c0-.25-.25-.5-.5-.5H.5c-.281 0-.5.25-.5.5v1.25c0 .281.219.5.5.5z" fill="#000"/></svg> &nbsp; ' + zilvercms.translationHelper('header.menu'));
            $(e.currentTarget).removeClass('open');
        } else {
            $(e.currentTarget).addClass('open');
            $(e.currentTarget).html('<svg width="14" height="16" style="padding-top:1px" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m8.563 6 3.124-3.125a.928.928 0 0 0 0-1.375L11 .812a.928.928 0 0 0-1.375 0L6.5 3.938 3.344.813a.928.928 0 0 0-1.375 0L1.28 1.5a.928.928 0 0 0 0 1.375L4.406 6 1.281 9.156a.928.928 0 0 0 0 1.375l.688.688a.928.928 0 0 0 1.375 0L6.5 8.094l3.125 3.125a.928.928 0 0 0 1.375 0l.688-.688a.928.928 0 0 0 0-1.375L8.562 6Z" fill="#000"/></svg> &nbsp; ' + zilvercms.translationHelper('header.close'));
        }
    });

    // Mobile nav account
    $('body').on('click','.js-toggle-accountmenu-nav', e => {
        $('.js-account-nav-menu').slideToggle('fast');
        if ($(e.currentTarget).hasClass('open')) {
            $(e.currentTarget).html('<svg width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 8c2.188 0 4-1.781 4-4 0-2.188-1.813-4-4-4-2.219 0-4 1.813-4 4 0 2.219 1.781 4 4 4zm2.781 1H9.25c-.688.344-1.438.5-2.25.5-.813 0-1.594-.156-2.281-.5h-.532C1.876 9 0 10.906 0 13.219V14.5c0 .844.656 1.5 1.5 1.5h11a1.5 1.5 0 001.5-1.5v-1.281C14 10.906 12.094 9 9.781 9z" fill="#000"/></svg>');
            $(e.currentTarget).removeClass('open');
        } else {
            $(e.currentTarget).addClass('open');
            $(e.currentTarget).html('<svg width="14" height="16"  style="padding-top:1px" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m8.563 6 3.124-3.125a.928.928 0 0 0 0-1.375L11 .812a.928.928 0 0 0-1.375 0L6.5 3.938 3.344.813a.928.928 0 0 0-1.375 0L1.28 1.5a.928.928 0 0 0 0 1.375L4.406 6 1.281 9.156a.928.928 0 0 0 0 1.375l.688.688a.928.928 0 0 0 1.375 0L6.5 8.094l3.125 3.125a.928.928 0 0 0 1.375 0l.688-.688a.928.928 0 0 0 0-1.375L8.562 6Z" fill="#000"/></svg>');
        }
    });

    // Account menu toggle on Customer pages
    $('body').on('click', '.js-toggle-header-accountmenu', () => {
        $('.js-header-accountmenu').slideToggle('fast');
        $('.js-header-overlay').slideToggle();

        if($('.js-header-accountmenu').css('display') === 'block') {         
            $('body').on('click','.js-header-overlay', e => {
                $('.js-header-accountmenu').slideUp('fast');
                $('.js-header-overlay').slideUp();
            });
        }
    });
});